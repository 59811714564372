import { template as template_e0cd5ee2ce674d55b05c9d0f3f685f01 } from "@ember/template-compiler";
const WelcomeHeader = template_e0cd5ee2ce674d55b05c9d0f3f685f01(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
