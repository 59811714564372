import { template as template_2678b6c3eee44d40922cc17e57c88916 } from "@ember/template-compiler";
const FKControlMenuContainer = template_2678b6c3eee44d40922cc17e57c88916(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
