import { template as template_c69ac3301efe44129e66a76b1673f25e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_c69ac3301efe44129e66a76b1673f25e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
