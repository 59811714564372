import { template as template_e6d21bf6db004cd3a0e21d44cf121060 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e6d21bf6db004cd3a0e21d44cf121060(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
