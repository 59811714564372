import { template as template_4cf99da7080248b5ab891a1b95c32412 } from "@ember/template-compiler";
const FKText = template_4cf99da7080248b5ab891a1b95c32412(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
